import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import Pagination, { PageItem } from "../components/Pagination";

import PostCard from "../components/PostCard";
import covidImage from "../assets/image/jpeg/covid.jpg";
import shareMasterImage from "../assets/image/png/share-master-small.png";
import japanImage from "../assets/image/jpeg/japan.jpg";
import taiwanImage from "../assets/image/jpeg/taiwan.jpg";
import usaImage from "../assets/image/jpeg/usa.jpg";
import franceImage from "../assets/image/jpeg/france.jpg";
import germanyImage from "../assets/image/jpeg/germany.jpg";
import productImage from "../assets/image/jpeg/australia-products.jpg";
import oldMailbox from "../assets/image/jpeg/old-mailbox.jpg";
import hotsnailServicesImage from "../assets/image/jpeg/hotsnail-services.jpg";

import styles from '../assets/styles/blog.module.css';


const BlogRegular = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Title variant="hero">HotSnail Blog</Title>
                <Text>
                  Musings and thoughts from HotSnail
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="position-relative">
          <Container>
            <Row className="align-items-center justify-content-center">
            <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={shareMasterImage}
                  preTitle="Jan 1, 2024"
                  title="Introducing ShareMaster"
                  url="/blog/introducing-sharemaster"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  SharePointPro have just released their new product - ShareMaster!
                </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={shareMasterImage}
                  preTitle="Jan 20, 2024"
                  title="Rolling back file version in SharePoint"
                  url="/blog/Reset-SharePoint-Version"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  Rollback SharePoint File Version with ShareMaster
                </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={covidImage}
                  preTitle="Jul 24, 2021"
                  title="World recovering from covid"
                  url="/blog/Covid-19"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  The Covid19 pandemic affected everything, and the impact of the virus continues to ravage most sectors.
                </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={japanImage}
                  imgClass={styles.blogGalleryImage}
                  preTitle="Jan 16, 2020"
                  title="Sending Australian products to Japan"
                  url="/blog/Australia-to-japan"
                  readMore
                >
                  Are you tired of getting damaged packages despite paying hundreds of dollars in delivery? This has finally come to an end with the introduction of our service!
                </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={taiwanImage}
                  preTitle="Feb 10, 2020"
                  title="The best solution to get your orders delivered from Australia to Taiwan!"
                  imgClass={styles.blogGalleryImage}
                  url="/blog/Australia-to-taiwan"
                  readMore
                >
                  Ever wondered how you can reduce your costs while ordering from Australia to Taiwan? Indeed there is a way....
                </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={usaImage}
                  preTitle="Feb 13, 2020"
                  title="Now get all your favorite Australian products on your doorstep in the United States via direct mail"
                  url="/blog/Australia-to-usa"
                  readMore
                >
                  The introduction of our parcel forwarding service has made directly mailing products from Australia to the US easier than ever. Using this service saves you a lot of time and worry...
                </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={franceImage}
                  preTitle="Feb 14, 2020"
                  title="Get your Australian product needs to be fulfilled while sitting at home in France"
                  url="/blog/Australia-to-france"
                  readMore
                >
                  We are a top-quality mail redirection service that have made getting your package from Australia to France more convenient with minimal hold-ups and fast service. All this is done by us sending you a local...
                </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={germanyImage}
                  preTitle="Feb 16, 2020"
                  title="Enjoy Australian products in Germany with no hold backs"
                  url="/blog/Australia-to-germany"
                  readMore
                >
                  It’s a common occurrence to find many Australian stores don’t ship internationally or to Germany, so we’re here to fix that for you...
                </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={productImage}
                  preTitle="Nov 21, 2020"
                  title="Australian products at your doorstep from anywhere, anytime"
                  url="/blog/Australia-products"
                  readMore
                >
                  Australia can provide some of the best shopping experiences and high-grade products compared to anywhere else around the globe, especially online...
                </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={hotsnailServicesImage}
                  preTitle="Nov 11, 2020"
                  title="HotSnail Services"
                  url="/blog/HotSnail-Services"
                  readMore
                >
                  HotSnail offers mail forwarding, parcel forwarding, mail scanning, storage and more! Are you wasting your precious time collecting your mail from the post office or...
                </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={oldMailbox}
                  preTitle="May 22, 2021"
                  title="Virtual mailboxes in Australia"
                  url="/blog/virtual-mailboxes-in-Australia"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  Are you an investor, and your business mails are inconvenient because you are always on the move? Or are you exhausted from coming back to where your physical address is located to sort out a pile of mail?
                </PostCard>
              </Col>
            </Row>
            <Box className="d-flex justify-content-center" mt={4}>
              <Pagination>
                <PageItem active>1</PageItem>
                <PageItem href="/blog-2">2</PageItem>
              </Pagination>
            </Box>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default BlogRegular;
